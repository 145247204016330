<template>
  <v-container class="pb-10">
    <!-- HEADER -->
    <div class="text-center section">
      <h2 class="h2">Holiday Schedule</h2>
      <v-toolbar-title v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </v-toolbar-title>
      <!-- {{ year }} -->
    </div>

    <transition name="fade" mode="out-in">
      <div v-if="panel.length == 0">
        <!-- ADDING HOLIDAY BUTTON -->
        <v-btn icon class="mr-3" @click="$refs.calendar.prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          @click="ScheduleDialog = true"
          color="#df6464"
          class="mb-1"
          dense
          small
          outlined
          >Add Holiday</v-btn
        >

        <!-- LEGEND -->
        <v-btn icon class="ml-5 float-right" @click="$refs.calendar.next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>

        <v-chip class="float-right mb-1" color="#016972" style="color: white"
          >Local</v-chip
        >
        <v-chip class="float-right mb-1 mr-2" color="#7a0202" style="color: white"
          >Japan</v-chip
        >
        <v-chip class="float-right mb-1 mr-2" color="#DF6464" style="color: white"
          >Date Today</v-chip
        >
        <span class="float-right text-sm-caption mt-2 mr-2" style="color: grey"
          >Legend:
        </span>

        <!-- CALENDAR -->

        <v-calendar
          v-model="value"
          class="mb-5"
          ref="calendar"
          color="#df6464"
          :events="allHolidaySchedule"
          :event-height="35"
          :event-color="getEventColor"
        ></v-calendar>
      </div>
    </transition>

    <v-expansion-panels v-model="panel" multiple inset hover focusable>
      <v-expansion-panel>
        <v-expansion-panel-header> Edit Holiday Record </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            height="46vh"
            fixed-header
            :headers="headers"
            :items="editableHoliday"
            :item-per-page="8"
            show-expand
            class="elevation-0"
          >
            <template v-slot:[`item.Department`]="{ item }">
              <v-row v-if="!item.Department || item.Department.length == 0">
                <span style="color: red; font-weight: bold">No Department Found</span>
              </v-row>
              <div v-else>
                <td v-for="(val, index) in item.Department" :key="index">
                  <v-chip color="#06d6a0" style="color: white">{{ val }}</v-chip>
                </td>
              </div>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td colspan="4">
                <v-row>
                  <v-col cols="5">
                    <v-select
                      v-model="item.Department"
                      @change="addDepartment(item.id)"
                      :items="listDepartment"
                      label="AddDepartment"
                      class="mt-5"
                      solo
                      dense
                      multiple
                    >
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-btn
                      @click="deleteRecord(item.id, item.HolidayReason)"
                      class="mt-5"
                      color="error "
                      dense
                      outlined
                      >Delete Record</v-btn
                    >
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- 
      //*****************/
      //    DIALOGS      /
      //*****************/
     -->
    <!-- DATETIME PICKER -->
    <v-dialog
      v-model="ScheduleDialog"
      scrollable
      :overlay="false"
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title style="color: grey">Add Holiday Schedule </v-card-title>
        <v-card-actions justify="center">
          <v-date-picker
            @change="HolidayReason = true"
            v-model="toStoreHoliday.CalendarDate"
            color="#df6464"
            full-width
          ></v-date-picker
          ><br />
        </v-card-actions>
        <v-card-text class="mt-n6">
          <v-row class="text-center">
            <v-col style="color: black; font-weight: bold; font-size: 15px"> Date </v-col>

            <v-col style="color: black; font-weight: bold; font-size: 15px">
              Reason
            </v-col>
            <v-col style="color: black; font-weight: bold; font-size: 15px"> Type </v-col>
            <v-col style="color: black; font-weight: bold; font-size: 15px">
              Action
            </v-col>
          </v-row>
          <v-container v-scrollbar>
            <v-row
              class="text-center"
              v-for="(val, index) in insertHolidaySchedule"
              :key="index"
              @mouseover="hoverCol = index"
              @mouseleave="index = ''"
            >
              <v-col> {{ val.CalendarDate }} </v-col>

              <v-col> {{ val.HolidayReason }} </v-col>
              <v-col>
                {{ val.Country }}
              </v-col>
              <v-col v-if="hoverCol == index">
                <span
                  style="color: red; cursor: pointer"
                  @click="insertHolidaySchedule.splice(index, 1)"
                  >Remove</span
                >
              </v-col>
              <v-col v-else></v-col>
            </v-row>
          </v-container>
          <v-btn @click="submitHoliday" color="success" block dense outlined
            >Submit Holiday</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- HOLIDAY REASON -->
    <v-dialog
      v-model="HolidayReason"
      scrollable
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title style="color: grey">Holiday Reason.</v-card-title>
        <v-card-actions>
          <v-textarea
            v-model="toStoreHoliday.HolidayReason"
            solo
            dense
            counter
            outlined
            filled
            color="#df6464"
          ></v-textarea>
        </v-card-actions>
        <v-radio-group class="mt-n7 mb-3" v-model="toStoreHoliday.Country" dense row>
          <v-spacer></v-spacer>
          <v-radio color="#df6464" label="Local" value="Local"></v-radio>
          <v-radio color="#df6464" label="Japan" value="Japan"></v-radio>
          <v-spacer></v-spacer>
        </v-radio-group>

        <v-btn @click="insertHoliday" color="success" class="ma-2 mt-n5">Add</v-btn>
      </v-card>
    </v-dialog>

    <!-- WAITING MODAL -->
    <v-dialog v-model="waitingDialog" v-if="waitingDialog" persistent max-width="200px">
      <v-card class="pa-3 text-center">
        <b style="font-size: 15px; font-family: Arial, Helvetica, sans-serif">{{
          language == "en" ? "Please Wait..." : "お待ちください..."
        }}</b>
        <v-img src="../../assets/loading.gif" width="50" height="50" style="margin: auto">
        </v-img>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import objects from "../../functions/alert";
import HRDSQL from "../../functions/httpRequest/ExternalConnection/hrdSql";
import holidayTable from "../../functions/httpRequest/ExternalConnection/HolidayTable";
import hrdappsAPI from "../../functions/httpRequest/hrdappsAPI/allConnection";

export default {
  data() {
    return {
      value: "",
      hoverCol: "",
      year: moment().format("YYYY"),
      ScheduleDialog: false,
      HolidayReason: false,
      waitingDialog: false,
      toStoreHoliday: {},
      departmentList: [],
      insertHolidaySchedule: [],
      allHolidaySchedule: [],
      editableHoliday: [],
      listDepartment: [],
      expanded: [],
      panel: [],
      headers: [
        { text: "Holiday Date", align: "start", value: "CalendarDate" },
        { text: "Holiday Reason", value: "HolidayReason" },
        { text: "Type", value: "Country" },
        { text: "Department", value: "Department" },
        { text: "Action", value: "data-table-expand" },
      ],
    };
  },
  created() {
    // CAll FUNCTIONS
    this.getHolidayEvents();
    this.getAllDepartment();
    this.getPlannersHoliday();
  },
  computed: {
    // LANGUAGE LOCALE
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    //**************************************//
    //  GETTING RECORDS FROM HOLIDAY TABLE  //
    //**************************************//
    getPlannersHoliday() {
      HRDSQL.Calendar().then((res) => {
        const data = res.data;
        // console.log(data);
        data.filter((rec) => {
          this.allHolidaySchedule.push({
            name: rec.IsHolidayPlanner
              ? "Planners Holiday"
              : rec.IsHolidaySalesman
              ? "Salesman Holiday"
              : rec.IsHolidayJapan
              ? "Japan Holiday"
              : " ",
            start: rec.CalendarDate,
            end: rec.CalendarDate,
            Country: "Japan",
            color:
              rec.IsHolidayPlanner || rec.IsHolidaySalesman || rec.IsHolidayJapan
                ? "#7A0202"
                : "",
          });
        });
      });
    },
    getEditableHolidayRecord() {
      holidayTable.Get().then((res) => (this.editableHoliday = res.data));
    },
    //**************************************//
    //   DELETING HOLIDAY RECORD FUNCTION   //
    //**************************************//

    deleteRecord(index, val) {
      // SHOW CONFIRM DIALOG
      this.$confirm({
        title: "Delete",
        message: "Are you sure you want to delete?",
        button: {
          no: this.$t("dialog.no"),
          yes: this.$t("dialog.yes"),
        },
        callback: (confirm) => {
          // IF YES
          if (confirm) {
            this.waitingDialog = true;
            holidayTable.Delete(index).then(() => {
              this.allHolidaySchedule = this.allHolidaySchedule.filter(
                (el) => val != el.name
              );
              this.editableHoliday = this.editableHoliday.filter((el) => index != el.id);
              this.$toast.success("Deleted Successfully", objects.alert);
              this.waitingDialog = false;
            });
          }
        },
      });
    },
    //*******************************************//
    //  ADDING DEPARTMENT WILL RENDER IN HOLIDAY //
    //*******************************************//

    addDepartment(index) {
      const FilterDepartment = this.editableHoliday.filter((el) => index == el.id);
      const StringDepartment = {
        Department: `${FilterDepartment[0].Department.toString()}`,
      };
      // SEND UPDATE
      holidayTable.Update(StringDepartment, index);
    },
    // GET COLOR LEGEND
    getEventColor: (event) => event.color,
    //**************************************//
    //  GETTING RECORDS FROM HOLIDAY TABLE  //
    //**************************************//

    async getHolidayEvents() {
      await holidayTable.Get().then((res) => {
        const data = res.data;
        data.forEach((el, index) => {
          data[index].Department = el.Department ? el.Department.split(",") : null;
          this.allHolidaySchedule.push({
            name: el.HolidayReason,
            start: el.CalendarDate,
            end: el.CalendarDate,
            Country: el.Country,
            color: el.Country == "Local" ? "#016972" : "#7a0202",
          });
        });

        this.editableHoliday = data;
      });
    },
    //**********************************************//
    //  GET ALL DEPARTMENT IN HRD PLAN PRODUCTION   //
    //**********************************************//

    getAllDepartment() {
      hrdappsAPI.HRDDepartmentlist().then((res) => {
        const data = res;
        this.listDepartment = [...new Set(data.map((el) => el.DepartmentName))].sort();
      });
    },
    //**************************************//
    //      INSERT HOLIDAY FUNCTION         //
    //**************************************//

    insertHoliday() {
      if (!this.toStoreHoliday.HolidayReason)
        return this.$toast.error("Input Holiday Reason", objects.alert);
      else if (!this.toStoreHoliday.Country)
        return this.$toast.error("Select Country", objects.alert);

      this.insertHolidaySchedule.push(this.toStoreHoliday);
      this.toStoreHoliday = {};
      this.HolidayReason = false;
    },
    //***********************************************//
    //      INSERT HOLIDAY IN DATABASE FUNCTION      //
    //***********************************************//

    submitHoliday() {
      // CHECK IF !== 0
      if (this.insertHolidaySchedule.length == 0)
        return this.$toast.error("Fill-up all fields", objects.alert);

      this.waitingDialog = true;
      const promises = [];
      // INSERT PROMISE AND SAVE TO DATABASE
      // ENABLE MULTIPLE SENDING AWAIT BY USING PROMISE
      for (let x in this.insertHolidaySchedule) {
        promises.push(
          holidayTable.Post(this.insertHolidaySchedule[x]).catch(() => {
            this.waitingDialog = false;
            this.$toast.error("Internal Server Error", objects.alert);
          })
        );
      }
      //  AFTER SAVING ALL RECORDS
      Promise.all(promises).then(() => {
        // PUSH NEW RECORD IN ARRAY TO BE SEEN AUTOMATICALLY IN SYSTEM
        for (let x in this.insertHolidaySchedule) {
          this.allHolidaySchedule.push({
            name: this.insertHolidaySchedule[x].HolidayReason,
            start: this.insertHolidaySchedule[x].CalendarDate,
            end: this.insertHolidaySchedule[x].CalendarDate,
            color:
              this.insertHolidaySchedule[x].Country == "Local" ? "#016972" : "#7a0202",
          });
          if (this.insertHolidaySchedule.length - 1 == x) this.insertHolidaySchedule = [];

          if (this.insertHolidaySchedule.length == 0) {
            this.waitingDialog = false;
            this.$toast.success("Inserted Successfully", objects.alert);
            this.ScheduleDialog = false;
          }
        }
        this.getEditableHolidayRecord();
      });
    },
  },
};
</script>
